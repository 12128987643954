<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col md="4" offset-md="4">
        <v-card class="mx-auto" max-width="370" shaped raised>
          <v-card-title class="pa-7">
            <img
              class="center"
              height="100px"
              src="./../public/mark-v2-logo.svg"
            />
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-form>
                    <v-text-field
                      v-model="username"  
                      label="Username"
                      outlined
                      dense
                      prepend-icon="mdi-account"
                      @keypress.enter="login()"
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      :type="show_password ? 'text' : 'password'"
                      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                      label="Password"
                      outlined
                      dense
                      prepend-icon="mdi-lock-question"
                      @keypress.enter="login()"
                      @click:append="show_password = !show_password"
                    ></v-text-field>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="pb-1">
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="disableLoginButton"
                    block
                    @click="login()"
                    >Login</v-btn
                  >
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12" class="pt-1">
                  <v-btn
                    color="primary"
                    :loading="loading"
                    block
                    @click="registration()"
                    >Registration</v-btn
                  >
                </v-col>
              </v-row> -->
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Services from "@/services/Services";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      show_password: false,
    };
  },
  computed: {
    disableLoginButton() {
      const status = this.password === "" || !this.password ? true : false;

      return status;
    },
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        const user = (
          await Services.Login({
            username: this.username,
            password: this.password,
          })
        ).data;
        
        await this.$store.dispatch("dispatchEmployee", {
          user_id: user.employee.user_id,
          email: user.employee.email,
          name: user.employee.name,
          username: user.employee.username,
          password_changed: user.password_changed,
          is_agent: user.employee.is_agent,
          auto_logout: user.auto_logout,
          enable_mark_logo: user.enable_mark_logo,
          user_designation : user.employee.user_designation,
          company_role_id : user.employee.company_role_id,
        });

        await this.$store.dispatch("dispatchToken", user.token);

        await this.$store.dispatch("dispatchClientStatus", user.client);

        if (user.company) {
          await this.$store.dispatch("dispatchCompany", {
            id: user.company.id,
            name: user.company.name,
            code: user.company.code,
          });

          await this.$store.dispatch(
            "dispatchCompanySettings",
            user.company_settings
          );

          this.$store.dispatch("dispatchCompanyMenu", user.menu);
        }        

        if (user.sub_company) {
          await this.$store.dispatch("dispatchSubCompany", {
            id: user.sub_company.id,
            name: user.sub_company.name,
            code: user.sub_company.code,
          });

          this.$store.dispatch("dispatchSubCompanyMenu", user.menu);
        }        
        if (user.menu.length) {
          this.$store.dispatch("dispatchMainMenu", user.menu);
          this.$store.dispatch("dispatchMenu", user.menu);
          this.$router.push({
            path: user.menu[0].link,
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        } else {
          if (error.response.status === 403) {
            this.$infoBar({
              type: "error",
              text: "Wrong username or password",
            });
          }
          if (error.response.status === 404) {
            this.$infoBar({
              type: "error",
              text: "Username and Password doesn't exist",
            });
          }
          if (error.response.status === 401) {
            this.$infoBar({
              type: "error",
              text: "Unauthorized Access",
            });
          }
          if (error.response.status === 451) {
            this.$infoBar({
              type: "error",
              text: "Company has been suspended!",
            });
          }
          if (error.response.status === 423) {
            this.$infoBar({
              type: "error",
              text: "Company has not yet been approved!",
            });
          }
            if (error.response.status === 422) {
            this.$infoBar({
              type: "error",
              text: "Please Contact your Main Company!",
            });
          }
           if (error.response.status === 428) {
            this.$infoBar({
              type: "error",
              text: "Please Contact your Company!",
            });
          }
          if (error.response.status === 500) {
            this.$infoBar({
              type: "error",
              text: "An error has occured while trying to log in",
            });
          }
        }
      }
      this.loading = false;
    },
    // solartech
    registration() {
      this.$router.push({
        path: "/registration",
      });
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}
</style>
